"use client"

import { useSaveReportStore } from "@/store/use-save-report-store";
import { API_LIST } from "@/utlity/api-list";
import { apiService } from "@/utlity/api-service";
import { createContext, useContext, useMemo } from "react";

// Define the shape of the context
interface ReportContextType {
    isSaved: boolean;
    saveReport: () => Promise<void>;
}

// Create the context
const ReportContext = createContext<ReportContextType | undefined>(undefined);

// Provider Component
export const ReportProvider = ({ children }: { children: React.ReactNode }) => {
    const { saveReportRequestBody, isSaved } = useSaveReportStore();

    const saveReport = async () => {
        try {
            await apiService.post(API_LIST.SAVE_REPORT, saveReportRequestBody);
        } catch (error) {
            console.error("Error saving report:", error);
        }
    };

    // Use useMemo to optimize performance and prevent unnecessary re-renders
    const contextValue = useMemo(() => ({ isSaved, saveReport }), [isSaved]);

    return <ReportContext.Provider value={contextValue}>{children}</ReportContext.Provider>;
};

// Custom hook to use the context
export const useReport = () => {
    const context = useContext(ReportContext);
    if (!context) {
        throw new Error("useReport must be used within a ReportProvider");
    }
    return context;
};
