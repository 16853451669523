import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Geist\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-geist\"}],\"variableName\":\"geist\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/push-notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/context/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DarkThemeProvider"] */ "/app/src/context/dark-theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutProvider"] */ "/app/src/context/layout-side-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PermissionsProvider"] */ "/app/src/context/permissions-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReportProvider"] */ "/app/src/context/report-save-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollProvider"] */ "/app/src/context/scroll-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SplitScreenProvider"] */ "/app/src/context/split-screen-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/context/theme-provider.tsx");
